import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useShareholderBlocksQuery } from "../../../api/blockchain/company";
import { useEntitiesQuery } from "../../../api/rest/entities";
import { useTransferShares } from "../../../api/rest/events";
import { EventFormData } from "../../../components/AddEvents/EventsWizard.utils";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { Select } from "../../../components/design-system/Select";
import { SelectEntity } from "../../../components/design-system/SelectEntity";
import { AddEntity } from "../../../components/Entities/AddEntity";
import { InfoText } from "../../../components/InfoText";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { dateToIsoString } from "../../../utils/date";
import { formatNumber } from "../../../utils/format";
import { shareOwnershipByType } from "../../../utils/shares";

const TransferByShareTypeFormSchema = z.object({
  date: z.string(),
  senderId: z.string(),
  recipientId: z.string(),
  sharesType: z.string(),
  sharesAmount: z.number(),
});

type FormProps = z.infer<typeof TransferByShareTypeFormSchema>;

type TransferSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  formId: string;
  onSuccess: (eventId: string) => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

const TransferNumber = ({
  currentCompany,
  formId,
  onSuccess,
  setFormData,
}: TransferSharesProps) => {
  const i18n = useTranslation();
  const form = useForm<FormProps>({
    mode: "onChange",
  });

  const formValues = form.watch();
  const orgNumber = currentCompany.orgNumber || "";

  const shareholdersQuery = useShareholderBlocksQuery(orgNumber);
  const allEntitiesQuery = useEntitiesQuery(orgNumber);
  const allEntities = useMemo(
    () => allEntitiesQuery.data || [],
    [allEntitiesQuery.data]
  );
  const shareHolders = useMemo(
    () => shareholdersQuery.data || [],
    [shareholdersQuery.data]
  );
  const senders = useMemo(
    () =>
      allEntities.filter((entity) =>
        shareHolders.some((element) => element.id === entity.id)
      ),
    [allEntities, shareHolders]
  );

  const selectedSender = useMemo(() => {
    return shareHolders.find((holder) => holder.id === formValues.senderId);
  }, [formValues.senderId, shareHolders]);

  const shareTypes = useMemo(
    () => shareOwnershipByType(selectedSender?.blocks || []),
    [selectedSender]
  );

  useEffect(() => {
    if (selectedSender && !form.getValues("sharesType")) {
      const senderFirstBlock = selectedSender?.blocks[0];
      if (senderFirstBlock) {
        form.setValue("sharesType", senderFirstBlock.type);
      }
    } else if (!selectedSender) {
      form.setValue("sharesType", "");
    }
  }, [selectedSender, form]);

  const lastEventDate = useLatestVersion();

  const transferSharesMutation = useTransferShares(orgNumber, {
    onSuccess: (eventId) => onSuccess(eventId),
  });

  const onSubmit = (data: FormProps) => {
    transferSharesMutation.mutate({
      date: data.date,
      holderIdentifier: data.senderId,
      targetIdentifier: data.recipientId,
      type: data.sharesType,
      amount: data.sharesAmount,
    });
  };

  const avaliableShares = useMemo(() => {
    const selectedShareType = shareTypes.find(
      ({ type }) => type === formValues.sharesType
    );

    return selectedShareType?.amount || 0;
  }, [formValues.sharesType, shareTypes]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: transferSharesMutation.isLoading,
    }));
  }, [transferSharesMutation.isLoading, setFormData]);

  useEffect(() => {
    const selectedTypes: string[] = [];
    if (formValues.sharesType) {
      selectedTypes.push(formValues.sharesType);
    }
    setFormData((data) => ({ ...data, selectedShareTypes: selectedTypes }));
  }, [formValues.sharesType, setFormData]);

  return (
    <form
      className="tw-space-y-6"
      onSubmit={form.handleSubmit(onSubmit)}
      id={formId}
    >
      <FormGroup>
        <InfoText content={i18n.t("events.transfer.form.date.tooltip")}>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
        </InfoText>
        <Controller
          control={form.control}
          render={({ field: { ref, name, onChange, value }, fieldState }) => (
            <>
              <Input
                id="date"
                value={value}
                ref={ref}
                name={name}
                onChange={onChange}
                type="date"
                className="tw-w-full"
                max={dateToIsoString(new Date())}
                min={lastEventDate && dateToIsoString(lastEventDate.date)}
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="date"
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="senderId">{i18n.t("label.sender")}</FormLabel>
        <Controller
          control={form.control}
          render={({ field: { onChange, value }, fieldState }) => (
            <>
              <SelectEntity
                id="senderId"
                options={senders.filter(
                  ({ id }) => id !== formValues.recipientId
                )}
                value={value}
                onChange={onChange}
                shareBlocks={shareHolders}
                isClearable
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="senderId"
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={form.control}
          render={({ field: { onChange, value }, fieldState }) => (
            <>
              <div className="tw-flex tw-justify-between">
                <div className="tw-flex tw-items-end">
                  <FormLabel htmlFor="recipient">
                    {i18n.t("label.recipient")}
                  </FormLabel>
                </div>
                <div>
                  <AddEntity
                    currentCompany={currentCompany}
                    onSuccess={(newEntity) => {
                      allEntitiesQuery.refetch();
                      onChange(newEntity.id);
                    }}
                  />
                </div>
              </div>

              <SelectEntity
                id="recipient"
                options={allEntities.filter(
                  ({ id }) => id !== formValues.senderId
                )}
                value={value}
                onChange={onChange}
                shareBlocks={shareHolders}
                isClearable
              />
              <FormError>{fieldState.error?.message}</FormError>
            </>
          )}
          name="recipientId"
          rules={{ required: i18n.t("error.validation.required") }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="sharesAmount">
          {i18n.t("label.numberOfShares")}
        </FormLabel>
        <div className="tw-grid tw-gap-2 md:tw-grid-cols-2">
          <div className="tw-space-y-2">
            <Input
              id="sharesAmount"
              {...form.register("sharesAmount", {
                required: i18n.t("error.validation.required"),
                ...(formValues.sharesType && {
                  min: {
                    value: 1,
                    message: i18n.t("error.validation.range", {
                      min: 1,
                      max: avaliableShares,
                    }),
                  },
                  max: {
                    value: avaliableShares,
                    message: i18n.t("error.validation.range", {
                      min: 1,
                      max: avaliableShares,
                    }),
                  },
                }),
                valueAsNumber: true,
              })}
              className="tw-flex-1"
              type="number"
              step={1}
            />
            <FormError>
              {form.formState.errors?.sharesAmount?.message}
            </FormError>
          </div>
          <div className="tw-space-y-2">
            <Controller
              control={form.control}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <Select
                    options={shareTypes}
                    getOptionLabel={(option) =>
                      `${option.type} (${formatNumber(option.amount)})`
                    }
                    getOptionValue={(option) => option.type}
                    value={shareTypes.find(({ type }) => type === value)}
                    onChange={(newValue) => onChange(newValue?.type)}
                    isDisabled={!formValues.senderId}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              rules={{
                required: i18n.t("error.validation.required"),
              }}
              name="sharesType"
            />
          </div>
        </div>
      </FormGroup>
      {transferSharesMutation.error && (
        <FormErrorList
          error={transferSharesMutation.error}
          className="tw-py-2"
        />
      )}
    </form>
  );
};

export default TransferNumber;
export type { FormProps };
