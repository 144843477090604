import { isEqual } from "lodash";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useShareTypesQuery } from "../../../api/blockchain/company";
import { useShareTypeUpdate } from "../../../api/rest/events";
import { EventFormData } from "../../../components/AddEvents/EventsWizard.utils";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { Input } from "../../../components/design-system/Input";
import { Select } from "../../../components/design-system/Select";
import { SelectClauses } from "../../../components/ShareTypes/SelectRestrictiveConditions";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import type { Condition } from "../../../types/models/shares";
import { dateToIsoString } from "../../../utils/date";

type ShareTypeUpdateProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: (eventId: string) => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

type FormProps = {
  date: string;
  type: string;
  condition: Condition;
};

const defaultCondition = {
  consent: false,
  conversion: false,
  preemption: false,
  offerOfFirstRefusal: false,
  redemption: false,
};

const shareTypeUpdateFormId = "share-type-update-form";

const ShareTypeUpdate = ({
  currentCompany,
  onSuccess,
  setFormData,
}: ShareTypeUpdateProps) => {
  const i18n = useTranslation();
  const form = useForm<FormProps>({
    mode: "onChange",
    defaultValues: { condition: defaultCondition },
  });
  const shareType = form.watch("type");
  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");
  const shareTypes = (shareTypesQuery.data || []).filter((x) => x.isUsed);
  const lastEventDate = useLatestVersion();

  const mutation = useShareTypeUpdate(currentCompany.orgNumber, shareType, {
    onSuccess: (eventId) => onSuccess(eventId),
  });

  useEffect(() => {
    setFormData((data) => ({ ...data, formId: shareTypeUpdateFormId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  useEffect(() => {
    setFormData((data) => ({ ...data, selectedShareTypes: [shareType] }));
  }, [shareType, setFormData]);

  const handleSubmit = (data: FormProps) => {
    if (
      isEqual(
        data.condition,
        shareTypes.find((x) => x.name === shareType)?.condition
      )
    ) {
      form.setError("condition", {
        type: "manual",
        message: i18n.t("error.verification.noChanges"),
      });
      return;
    }
    mutation.mutate({
      date: data.date,
      condition: data.condition,
    });
  };

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.shareTypeUpdate.title")}</h4>
        </div>
      </header>
      <form
        id={shareTypeUpdateFormId}
        className="tw-space-y-6"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormGroup>
          <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
          <Controller
            control={form.control}
            render={({ field: { ref, name, onChange, value }, fieldState }) => (
              <>
                <Input
                  id="date"
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  type="date"
                  className="tw-w-full"
                  max={dateToIsoString(new Date())}
                  min={lastEventDate && dateToIsoString(lastEventDate.date)}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="date"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="shareClass">
            {i18n.t("label.shareClass")}
          </FormLabel>
          <Controller
            control={form.control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <Select
                  name="shareClassSelect"
                  options={shareTypes}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.name}
                  value={shareTypes.find(({ name }) => name === value)}
                  onChange={(newValue) => {
                    onChange(newValue?.name);
                    form.setValue(
                      "condition",
                      newValue?.condition || defaultCondition
                    );
                  }}
                />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            name="type"
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="condition" isOptional>
            {i18n.t("shares.restrictiveConditions")}
          </FormLabel>
          <Controller
            name="condition"
            control={form.control}
            render={({ field: { onChange, value }, fieldState }) => (
              <>
                <SelectClauses value={value} onChange={onChange} />
                <FormError>{fieldState.error?.message}</FormError>
              </>
            )}
            rules={{ required: i18n.t("error.validation.required") }}
          />
        </FormGroup>
        {mutation.error && <FormErrorList error={mutation.error} />}
      </form>
    </div>
  );
};

export default ShareTypeUpdate;
