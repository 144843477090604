import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  useLedgerQuery,
  useShareholderBlocksQuery,
} from "../../../api/blockchain/company";
import { useReverseShareSplit } from "../../../api/rest/events";
import { EventFormData } from "../../../components/AddEvents/EventsWizard.utils";
import { Alert } from "../../../components/design-system/Alert";
import {
  FormError,
  FormErrorList,
  FormGroup,
  FormLabel,
} from "../../../components/design-system/FormGroup";
import { ArrowDownIcon } from "../../../components/design-system/icons";
import { Input } from "../../../components/design-system/Input";
import type { TEventSummaryMetric } from "../../../components/EventSummary";
import {
  EventFormWrapper,
  EventSummarySectionList,
} from "../../../components/EventSummary";
import useLatestVersion from "../../../hooks/useLatestVersion";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import { dateToIsoString } from "../../../utils/date";
import { getSplitMetrics } from "../../../utils/shares";

type ReverseSplitSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: (eventId: string) => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

type FormProps = { ratioX: number; ratioY: number; date: string };

const formId = "reverse-split-shares-form";

const ReverseSplitShares = ({
  currentCompany,
  onSuccess,
  setFormData,
}: ReverseSplitSharesProps) => {
  const i18n = useTranslation();
  const form = useForm<FormProps>({ mode: "onChange" });
  const ledgerQuery = useLedgerQuery(currentCompany.orgNumber, "");
  const lastEventDate = useLatestVersion();

  const mutation = useReverseShareSplit(currentCompany.orgNumber, {
    onSuccess: (eventId) => onSuccess(eventId),
  });

  useEffect(() => {
    setFormData((data) => ({ ...data, formId }));
  }, [setFormData]);

  useEffect(() => {
    setFormData((data) => ({
      ...data,
      loading: mutation.isLoading,
    }));
  }, [mutation.isLoading, setFormData]);

  const LCDRatio = (num1Input: number, num2Input: number) => {
    let num1 = num1Input;
    let num2 = num2Input;
    for (let num = num2; num > 1; num--) {
      if (num1 % num === 0 && num2 % num === 0) {
        num1 = num1 / num;
        num2 = num2 / num;
      }
    }

    return { num1, num2 };
  };

  const handleSubmit = (data: FormProps) => {
    if (!isModifierValid) {
      form.setError("ratioX", {
        type: "manual",
        message: i18n.t("error.validation.split.decrease"),
      });
      form.setError("ratioY", {
        type: "manual",
        message: i18n.t("error.validation.split.decrease"),
      });
      return;
    }
    if (!validRanges) {
      form.setError("ratioX", {
        type: "manual",
        message: i18n.t("error.validation.split.ratio"),
      });
      form.setError("ratioY", {
        type: "manual",
        message: i18n.t("error.validation.split.ratio"),
      });
      return;
    }

    mutation.mutate({
      date: data.date,
      ratio: { x: data.ratioX, y: data.ratioY },
    });
  };

  const [ratioX, ratioY] = form.watch(["ratioX", "ratioY"]);
  const modifier = ratioY / ratioX;
  const isModifierValid = modifier < 1;
  const metrics = getSplitMetrics(modifier || 1, ledgerQuery.data);
  const metricsWithLabel: Record<string, TEventSummaryMetric> = {
    shareCapital: {
      label: `${i18n.t("label.shareCapital")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.shareCapital,
    },
    quotaValue: {
      label: `${i18n.t("label.quotaValue")} (${
        currentCompany.settings?.currency
      })`,
      format: "number",
      ...metrics.quotaValue,
    },
    numberOfShares: {
      label: i18n.t("label.shares"),
      format: "number",
      ...metrics.numberOfShares,
    },
  };
  const { dirtyFields, errors } = form.formState;

  const shareHoldersQuery = useShareholderBlocksQuery(currentCompany.orgNumber);
  const rangeTotals = (shareHoldersQuery.data || [])
    .map((x) => x.blocks.map((b) => b.end - b.start + 1))
    .flat();
  const validRanges = rangeTotals
    .map((x) => Math.round(x * modifier) === x * modifier)
    .every((x) => x);

  return (
    <div>
      <header className="tw-flex tw-justify-between tw-pb-6">
        <div>
          <h4>{i18n.t("events.reverseSplit.title")}</h4>
        </div>
      </header>
      <EventFormWrapper
        summary={
          <EventSummarySectionList metrics={Object.values(metricsWithLabel)} />
        }
      >
        <form
          className="tw-space-y-6"
          onSubmit={form.handleSubmit(handleSubmit)}
          id={formId}
        >
          <FormGroup>
            <FormLabel htmlFor="date">{i18n.t("label.date")}</FormLabel>
            <Controller
              control={form.control}
              render={({
                field: { ref, name, onChange, value },
                fieldState,
              }) => (
                <>
                  <Input
                    id="date"
                    value={value}
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    type="date"
                    className="tw-w-full"
                    max={dateToIsoString(new Date())}
                    min={lastEventDate && dateToIsoString(lastEventDate.date)}
                  />
                  <FormError>{fieldState.error?.message}</FormError>
                </>
              )}
              name="date"
              rules={{ required: i18n.t("error.validation.required") }}
            />
          </FormGroup>
          <div className="tw-space-y-2">
            <FormGroup>
              <FormLabel htmlFor="ratioX">
                {i18n.t("events.split.ratioX")}
              </FormLabel>
              <Input
                id="ratioX"
                {...form.register("ratioX", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  validate: (val) => {
                    if (Number.isNaN(val)) {
                      return i18n.t("error.validation.required");
                    }
                    if (!(val > 0)) {
                      return i18n.t("error.validation.range.min.exclusive", {
                        min: 0,
                      });
                    }

                    return true;
                  },
                })}
                type="number"
              />
              <FormError>{form.formState.errors.ratioX?.message}</FormError>
            </FormGroup>
            <ArrowDownIcon className="tw-h-7 tw-w-7 tw-text-secondary" />
            <FormGroup>
              <FormLabel htmlFor="ratioY">
                {i18n.t("events.split.ratioY")}
              </FormLabel>
              <Input
                id="ratioY"
                {...form.register("ratioY", {
                  required: i18n.t("error.validation.required"),
                  valueAsNumber: true,
                  validate: (val) => {
                    if (Number.isNaN(val)) {
                      return i18n.t("error.validation.required");
                    }
                    if (!(val > 0)) {
                      return i18n.t("error.validation.range.min.exclusive", {
                        min: 0,
                      });
                    }

                    return true;
                  },
                })}
                type="number"
              />
              <FormError>{form.formState.errors.ratioY?.message}</FormError>
            </FormGroup>
            {isModifierValid ? (
              <div className="tw-pt-2">
                <Alert type="neutral">
                  {i18n.t("events.split.form.description", {
                    x: LCDRatio(ratioX, ratioY).num1,
                    y: LCDRatio(ratioX, ratioY).num2,
                  })}
                </Alert>
              </div>
            ) : (
              dirtyFields.ratioX &&
              dirtyFields.ratioY &&
              !errors.ratioX &&
              !errors.ratioY && (
                <div className="tw-pt-2">
                  <Alert type="error">
                    {i18n.t("error.validation.split.decrease")}
                  </Alert>
                </div>
              )
            )}
          </div>
          {mutation.error && <FormErrorList error={mutation.error} />}
        </form>
      </EventFormWrapper>
    </div>
  );
};

export default ReverseSplitShares;
