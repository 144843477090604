import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EventFormData } from "../../../components/AddEvents/EventsWizard.utils";
import { Description } from "../../../components/design-system/Description";
import { FormLabel } from "../../../components/design-system/FormGroup";
import { RadioGroup } from "../../../components/design-system/RadioGroup";
import type { CompanyInformation } from "../../../types/models/administration";
import type { CompanyInvolvement } from "../../../types/models/company";
import TransferNumber from "./TransferNumber";
import TransferRange from "./TransferRange";

type TransferSharesProps = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  onSuccess: (eventId: string) => void;
  setFormData: Dispatch<SetStateAction<EventFormData>>;
};

const formId = "transfer-shares-form";

const TransferShares = ({
  currentCompany,
  onSuccess,
  setFormData,
}: TransferSharesProps) => {
  const i18n = useTranslation();
  const [type, setType] = useState<"basic" | "range">("basic");

  const typeOptions = [
    {
      value: "basic",
      title: i18n.t("events.transfer.form.type.option1.title"),
    },
    {
      value: "range",
      title: i18n.t("events.transfer.form.type.option2.title"),
    },
  ];

  useEffect(() => {
    setFormData((data) => ({ ...data, formId }));
  }, []);

  return (
    <div>
      <h1 className="tw-text-3xl">{i18n.t("events.transfer.title")}</h1>
      <RadioGroup
        value={type}
        onChange={(newValue: "basic" | "range") => {
          setType(newValue);
        }}
        name="type"
        className="tw-mb-6"
      >
        <RadioGroup.Label>
          <FormLabel htmlFor="type" className="tw-mb-2">
            {i18n.t("events.transfer.form.type.label")}
          </FormLabel>
        </RadioGroup.Label>
        <div className="tw-flex tw-flex-col tw-gap-2 md:tw-flex-row">
          {typeOptions.map((option) => (
            <RadioGroup.Option key={option.value} value={option.value}>
              {({ checked }) => (
                <RadioGroup.OptionContent checked={checked}>
                  <Description title={option.title} />
                </RadioGroup.OptionContent>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {type === "basic" ? (
        <TransferNumber
          currentCompany={currentCompany}
          formId={formId}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      ) : (
        <TransferRange
          currentCompany={currentCompany}
          formId={formId}
          onSuccess={onSuccess}
          setFormData={setFormData}
        />
      )}
    </div>
  );
};

export default TransferShares;
