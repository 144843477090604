import { z } from "zod";

const AttachmentsSchema = z.record(
  z.array(
    z.object({
      name: z.string(),
      size: z.number(),
      created: z.number(),
      userId: z.string(),
    })
  )
);

type Attachments = z.infer<typeof AttachmentsSchema>;

const SignedUrlSchema = z.object({
  url: z.string(),
});

type SignedUrl = z.infer<typeof SignedUrlSchema>;

export { AttachmentsSchema, SignedUrlSchema };
export type { Attachments, SignedUrl };
