import {
  ChangeEvent,
  Dispatch,
  DragEvent,
  SetStateAction,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";

import { Alert } from "../Alert";
import { Badge } from "../Badge";
import { TrashIcon } from "../icons";

type AttachmentProps = {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
};

const maxFilesize = 10 * 1024 * 1024;
const allowedFiletypes = ["application/pdf"];

const Attachment = ({ files, setFiles }: AttachmentProps) => {
  const i18n = useTranslation();
  const [isDragging, setIsDragging] = useState<boolean>(false); // State to handle dragging
  const [invalidFiles, setInvalidFiles] = useState<string[]>([]);

  // Handle drag over event
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  // Handle drag leave event
  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const updateFiles = (newFiles: File[]) => {
    if (newFiles.length === 0) {
      return;
    }
    const validSelectedFiles = newFiles.filter(
      (f) => f.size <= maxFilesize && allowedFiletypes.includes(f.type)
    );
    setInvalidFiles(
      newFiles.filter((f) => !validSelectedFiles.includes(f)).map((f) => f.name)
    );
    setFiles((prevFiles) => [...prevFiles, ...validSelectedFiles]);
  };

  // Handle drop event (files dropped)
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    updateFiles(Array.from(e.dataTransfer.files));
  };

  // Handle file selection (when choosing files via input)
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      updateFiles(Array.from(e.target.files));
    }
  };

  return (
    <div className="tw-w-full">
      <div
        className={`tw-flex tw-flex-col tw-gap-4 tw-rounded-lg tw-border-2 tw-border-dashed tw-bg-gray-100 tw-p-4 tw-text-center tw-transition
          ${
            isDragging
              ? "tw-border-blue-400 tw-bg-blue-50"
              : "tw-border-gray-300"
          }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div>
          <p className="tw-text-lg tw-font-medium tw-text-primary">
            {i18n.t("events.attachments.title")}
          </p>

          <span className="tw-text-sm tw-text-secondary">
            <Trans
              components={{
                openLink: (
                  <label
                    htmlFor="fileInput"
                    className="tw-cursor-pointer tw-text-sm tw-font-medium tw-text-blue-600 tw-underline"
                  />
                ),
              }}
              i18nKey="events.attachments.description"
            />
          </span>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="fileInput"
            accept={allowedFiletypes.join(",")}
          />
        </div>
        {invalidFiles.length > 0 && (
          <Alert type="error" className="tw-justify-center tw-text-sm">
            {i18n.t("events.attachments.invalidFiles")}
            <br /> {invalidFiles.join(", ")}
          </Alert>
        )}
        {files.length > 0 && (
          <div className="tw-flex tw-flex-wrap tw-gap-2">
            {files.map((file, index) => (
              <Badge
                key={file.name}
                className="tw-flex tw-max-w-full tw-items-center tw-gap-1 tw-whitespace-nowrap tw-text-sm"
              >
                <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                  {file.name}
                </span>
                <button
                  onClick={() => setFiles(files.filter((_, i) => i !== index))}
                  type="button"
                >
                  <TrashIcon size={15} />
                </button>
              </Badge>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { Attachment };
